import React from "react"
import { injectIntl } from "react-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GatsbyImage } from "gatsby-plugin-image"
import slugify from "react-slugify"
import Download from "./download"

const Employee = ({
  firstName,
  lastName,
  job,
  image,
  email,
  phone,
  intl,
  ...other
}) => {
  const name = `${firstName} ${lastName}`

  return (
    <div className="col" {...other} id={slugify(name)}>
      <div className="w-20 h-20 overflow-hidden rounded-full mt-sm mb-sm">
        {image && (
          <GatsbyImage
            imgClassName="rounded-full bg-gray-lightest"
            image={image.asset.gatsbyImageData}
            alt={name}
          />
        )}
      </div>
      <div className="mb-xs">
        <div className="font-bold sm:text-lg">{name}</div>
        {typeof job === "object" && intl.locale in job && (
          <>{job[intl.locale]}</>
        )}
      </div>
      <div className="mb-md md:mb-lg">
        {email && (
          <>
            <a className="font-bold text-parent" href={`mailto:${email}`}>
              {email}
            </a>
            <br />
          </>
        )}
        {phone && (
          <>
            <a className="text-parent" href={`tel:${phone.machine}`}>
              <FontAwesomeIcon
                fixedWidth
                icon={["fal", "phone"]}
                className="mr-1"
              />
              {phone.human}
            </a>
            <br />
          </>
        )}
        <Download
          fileName={`/vcf/${slugify(name)}.${intl.locale}.vcf`}
          title={intl.formatMessage({
            id: "page.contact.business-card",
          })}
          iconName="address-card"
        />
      </div>
    </div>
  )
}

export default injectIntl(Employee)
