import React from "react"
import Employee from "./employee"

const StaffSection = ({ title, staff,  ...other }) => {
  if (!staff.length) return <></>

  return (
    <section className="container mb-md lg:mb-xl" {...other}>
      <div className="col">
        <h3 className="mt-0 text-black border-black section-heading">
          {title}
        </h3>
      </div>
      <div className="sm:flex sm:flex-wrap">
        {staff
          .map(({node}) => (
            <Employee key={node.id} {...node} className="col sm:w-1/2 lg:w-1/3" />
          ))}
      </div>
    </section>
  )
}

export default StaffSection
