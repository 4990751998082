import React from "react"
import Seo from "../components/seo"
import { injectIntl, FormattedMessage } from "react-intl"
import Intro from "../components/intro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Link from "../components/link"
import StaffSection from "../components/staff-section"
import { useStaticQuery, graphql } from "gatsby"

import info from "../data/info"

const ContactPage = ({ intl, locale }) => {
  const { staff, area } = useStaticQuery(
    graphql`
      query ContactQuery {
        area: allSanityArea(sort: {order: ASC, fields: order}) {
          edges {
            node {
              id
              title {
                de
                en
              }
            }
          }
        }
        staff: allSanityStaff(filter: {public: {eq: true}}, sort: {order: ASC, fields: [area___order, order]}) {
          edges {
            node {
              id
              firstName
              lastName
              job {
                de
                en
              }
              email
              phone {
                human
                machine
              }
              image {
                asset {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                }
              }
              area {
                id
                title {
                  de
                  en
                }
              }
            }
          }
        }
      }
    `
  )

    return (
        <>
            <Seo id="contact" description={
                intl.formatMessage({ id: "page.contact.description" })} />
            <Intro title={intl.formatMessage({ id: "page.contact.headline" })} narrow={true}>
                <p>
                    <strong>{info.company}</strong>
                    {info.subline &&
                        <strong><br />{info.subline}</strong>
                    }
                </p>

                <address dangerouslySetInnerHTML={{
                    __html: info.address.join('<br />') }} />

                {info.directionsLink &&
                    <p>
                        <Link to={info.directionsLink}>
                            <FormattedMessage id="global.plan-route" />
                        </Link>
                    </p>
                }

                <p>
                    <a className="text-parent font-bold" href={`mailto:${info.email}`}>
                        {info.email}
                    </a>
                </p>

                <p>
                    <a className="text-parent" href={`tel:${info.phone.machine}`}>
                        <FontAwesomeIcon fixedWidth icon={["fal", "phone"]} className="mr-1" />
                        {info.phone.human}
                    </a><br />
                    <a className="text-parent" href={`tel:${info.fax.machine}`}>
                        <FontAwesomeIcon fixedWidth icon={["fal", "fax"]} className="mr-1" />
                        {info.fax.human}
                    </a>
                </p>
            </Intro>
            <section className="container xl:max-w-wide">
                <h2 className="col h1 text-black">
                    <FormattedMessage id="title.staff" />
                </h2>
                <div className="col">
                  {area.edges.map(({ node }) => (
                    <StaffSection
                      key={node.id}
                      title={node.title[locale]}
                      staff={staff.edges.filter((item) => item.node.area.id === node.id)}
                      id={node.id}
                    />
                  ))}
                </div>
            </section>
        </>
    )
}

export default injectIntl(ContactPage)
